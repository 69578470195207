import styled from 'styled-components';

export const Wrapper = styled.div`
  min-height: 90vh;
  padding: 3rem 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    min-height: 70vh;
  }

  @media (max-width: 480px) {
    min-height: 90vh;
    flex-direction: column-reverse;
  }
`;

export const ErrorMessage = styled.div`
  flex: 1;

  h1 {
    font-family: 'Amaranth';
    font-size: 26pt;
  }
`;

export const ErrorImage = styled.div`
  flex: 1;
`;
