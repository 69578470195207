import React from 'react';
import { Layout, SEO, Container, Button } from 'components/common';
import { Wrapper, ErrorMessage, ErrorImage } from 'assets/js/404';
import errorIllustration from 'assets/illustrations/404-error.svg';

export default () => {
  function handleOnClick() {
    window.location.href = '/';
  }

  return (
    <Layout>
      <SEO title="404: Not found" location="/404" />
      <Wrapper as={Container}>
        <ErrorMessage>
          <div>404</div>
          <h1>
            Oops! Looks like <i>someone</i> may have disconnected the power cord.
          </h1>
          <p>Go back to the main page to continue your visit.</p>
          <Button onClick={() => handleOnClick()}>Back to main page</Button>
        </ErrorMessage>
        <ErrorImage>
          <img src={errorIllustration} alt="" />
        </ErrorImage>
      </Wrapper>
    </Layout>
  );
};
